export const countryCoordinates = [
	{name: "Afghanistan", x: 2122, y: 1856.5, size: "medium"},
	{name: "Angola", x: 1879, y: 2147.5, size: "medium"},
	{name: "Albania", x: 1889, y: 1808.5, size: "tiny"},
	{name: "Argentina", x: 1432, y: 2308.5, size: "medium"},
	{name: "Australia", x: 2515, y: 2231.5, size: "large"},
	{name: "Azerbaijan", x: 2032, y: 1814.5, size: "small"},
	{name: "Armenia", x: 2018, y: 1813.5, size: "tiny"},
	{name: "Burundi", x: 1954, y: 2095.5, size: "tiny"},
	{name: "Belgium", x: 1806, y: 1749.5, size: "tiny"},
	{name: "Benin", x: 1796, y: 2013.5, size: "small"},
	{name: "Burkina Faso", x: 1774, y: 1992.5, size: "small"},
	{name: "Bangladesh", x: 2278, y: 1921.5, size: "small"},
	{name: "Bulgaria", x: 1915, y: 1798.5, size: "tiny"},
	{name: "Bosnia and Herzegovina", x: 1873, y: 1790.5, size: "tiny"},
	{name: "Belarus", x: 1918, y: 1729.5, size: "small"},
	{name: "Belize", x: 1288, y: 1961.5, size: "tiny"},
	{name: "Bolivia", x: 1422, y: 2176.5, size: "medium"},
	{name: "Brazil", x: 1500, y: 2151.5, size: "large"},
	{name: "Brunei Darussalam", x: 2434, y: 2041.5, size: "tiny"},
	{name: "Bhutan", x: 2281, y: 1895.5, size: "tiny"},
	{name: "Central African Republic", x: 1890, y: 2028.5, size: "small"},
	{name: "Canada", x: 1260, y: 1711.5, size: "large"},
	{name: "China", x: 2309, y: 1835.5, size: "large"},
	{name: "Côte d'Ivoire", x: 1750, y: 2021.5, size: "small"},
	{name: "Cameroon", x: 1850, y: 2040.5, size: "small"},
	{name: "Democratic Republic of the Congo", x: 1890, y: 2091.5, size: "medium"},
	{name: "Republic of the Congo", x: 1868, y: 2075.5, size: "small"},
	{name: "Colombia", x: 1365, y: 2045.5, size: "medium"},
	{name: "Costa Rica", x: 1311, y: 2009.5, size: "tiny"},
	{name: "Cuba", x: 1341, y: 1929.5, size: "small"},
	{name: "Bahamas", x: 1341, y: 1929.5, size: "small"},
	{name: "Czech Republic", x: 1861, y: 1753.5, size: "tiny"},
	{name: "Austria", x: 1857, y: 1768.5, size: "tiny"},
	{name: "Switzerland", x: 1825, y: 1771.5, size: "tiny"},
	{name: "Germany", x: 1835, y: 1745.5, size: "small"},
	{name: "Djibouti", x: 2024, y: 1996.5, size: "tiny"},
	{name: "Denmark", x: 1827, y: 1714.5, size: "tiny"},
	{name: "Dominican Republic", x: 1390, y: 1951.5, size: "tiny"},
	{name: "Algeria", x: 1788, y: 1891.5, size: "medium"},
	{name: "Ecuador", x: 1339, y: 2083.5, size: "small"},
	{name: "Egypt", x: 1948, y: 1899.5, size: "medium"},
	{name: "Eritrea", x: 1998, y: 1970.5, size: "small"},
	{name: "Estonia", x: 1902, y: 1700.5, size: "tiny"},
	{name: "Ethiopia", x: 2007, y: 2012.5, size: "medium"},
	{name: "Finland", x: 1900, y: 1663.5, size: "small"},
	{name: "Gabon", x: 1849, y: 2077.5, size: "small"},
	{name: "United Kingdom", x: 1773, y: 1726.5, size: "small"},
	{name: "Georgia", x: 2007, y: 1802.5, size: "small"},
	{name: "Ghana", x: 1777, y: 2021.5, size: "small"},
	{name: "Guinea", x: 1719, y: 2002.5, size: "small"},
	{name: "The Gambia", x: 1698, y: 1985.5, size: "tiny"},
	{name: "Guinea-Bissau", x: 1697, y: 1994.5, size: "tiny"},
	{name: "Equatorial Guinea", x: 1841, y: 2061.5, size: "tiny"},
	{name: "Greece", x: 1898, y: 1821.5, size: "small"},
	{name: "Greenland", x: 1624, y: 1630.5, size: "medium"},
	{name: "Guatemala", x: 1278, y: 1972.5, size: "tiny"},
	{name: "Guyana", x: 1449, y: 2041.5, size: "small"},
	{name: "Honduras", x: 1293, y: 1977.5, size: "small"},
	{name: "Croatia", x: 1870, y: 1780.5, size: "small"},
	{name: "Haiti", x: 1382, y: 1950.5, size: "tiny"},
	{name: "Hungary", x: 1882, y: 1770.5, size: "tiny"},
	{name: "Indonesia", x: 2356, y: 2072.5, size: "small"},
	{name: "India", x: 2225, y: 1931.5, size: "large"},
	{name: "Ireland", x: 1746, y: 1731.5, size: "small"},
	{name: "Iran", x: 2068, y: 1863.5, size: "medium"},
	{name: "Iraq", x: 2020, y: 1858.5, size: "small"},
	{name: "Iceland", x: 1702, y: 1663.5, size: "small"},
	{name: "Israel", x: 1973, y: 1872.5, size: "tinier"},
	{name: "Palestine", x: 1976, y: 1866.5, size: "tinier"},
	{name: "Italy", x: 1849, y: 1799.5, size: "small"},
	{name: "Jamaica", x: 1353, y: 1956.5, size: "tiny"},
	{name: "Cayman Islands", x: 1333, y: 1955, size: "tiny"},
	{name: "Jordan", x: 1980, y: 1871.5, size: "tiny"},
	{name: "Japan", x: 2516, y: 1839.5, size: "small"},
	{name: "Kazakhstan", x: 2110, y: 1764.5, size: "medium"},
	{name: "Kenya", x: 1998, y: 2069.5, size: "small"},
	{name: "Kyrgyzstan", x: 2172, y: 1807.5, size: "small"},
	{name: "Cambodia", x: 2374, y: 1991.5, size: "small"},
	{name: "South Korea", x: 2463, y: 1837.5, size: "tiny"},
	{name: "Kuwait", x: 2044, y: 1884.5, size: "tiny"},
	{name: "Laos", x: 2356, y: 1943.5, size: "small"},
	{name: "Lebanon", x: 1978, y: 1853.5, size: "tinier"},
	{name: "Liberia", x: 1731, y: 2030.5, size: "tiny"},
	{name: "Libya", x: 1874, y: 1902.5, size: "medium"},
	{name: "Sri Lanka", x: 2240, y: 2021.5, size: "tiny"},
	{name: "Lesotho", x: 1938, y: 2262.5, size: "tiny"},
	{name: "Lithuania", x: 1897, y: 1721.5, size: "tiny"},
	{name: "Luxembourg", x: 1815, y: 1754.5, size: "tiny"},
	{name: "Latvia", x: 1900, y: 1711.5, size: "tiny"},
	{name: "Morocco", x: 1736, y: 1869.5, size: "medium"},
	{name: "Moldova", x: 1928, y: 1771.5, size: "tiny"},
	{name: "Madagascar", x: 2040, y: 2193, size: "small"},
	{name: "Mexico", x: 1210, y: 1919.5, size: "medium"},
	{name: "Macedonia", x: 1897, y: 1805.5, size: "tiny"},
	{name: "Mali", x: 1770, y: 1958.5, size: "medium"},
	{name: "Myanmar", x: 2316, y: 1941.5, size: "small"},
	{name: "Montenegro", x: 1883, y: 1797.5, size: "tiny"},
	{name: "Mongolia", x: 2296, y: 1771.5, size: "medium"},
	{name: "Mozambique", x: 1975, y: 2186.5, size: "small"},
	{name: "Mauritania", x: 1717, y: 1936.5, size: "medium"},
	{name: "Malawi", x: 1974, y: 2157.5, size: "tiny"},
	{name: "Malaysia", x: 2431, y: 2047.5, size: "small"},
	{name: "Namibia", x: 1870, y: 2218.5, size: "medium"},
	{name: "Botswana", x: 1907, y: 2215.5, size: "medium"},
	{name: "Niger", x: 1834, y: 1958.5, size: "medium"},
	{name: "Nigeria", x: 1820, y: 2014.5, size: "medium"},
	{name: "Nicaragua", x: 1303, y: 1989.5, size: "small"},
	{name: "Norway", x: 1824, y: 1681.5, size: "small"},
	{name: "Nepal", x: 2244, y: 1889.5, size: "small"},
	{name: "Oman", x: 2096, y: 1938.5, size: "small"},
	{name: "Pakistan", x: 2152, y: 1876.5, size: "medium"},
	{name: "Panama", x: 1325, y: 2018.5, size: "tiny"},
	{name: "Peru", x: 1355, y: 2130.5, size: "medium"},
	{name: "Philippines", x: 2462, y: 1965.5, size: "tiny"},
	{name: "Papua New Guinea", x: 2590, y: 2114.5, size: "small"},
	{name: "Poland", x: 1876, y: 1740.5, size: "small"},
	{name: "North Korea", x: 2443, y: 1813.5, size: "small"},
	{name: "Paraguay", x: 1460, y: 2222.5, size: "small"},
	{name: "Qatar", x: 2067, y: 1909.5, size: "tiny"},
	{name: "Romania", x: 1910, y: 1777.5, size: "small"},
	{name: "Rwanda", x: 1954, y: 2085, size: "tiny"},
	{name: "Western  Sahara", x: 1715, y: 1919, size: "tiny"},
	{name: "Saudi Arabia", x: 2028, y: 1915, size: "medium"},
	{name: "United Arab Emirates", x: 2078, y: 1920, size: "tiny"},
	{name: "Sudan", x: 1947, y: 1973, size: "medium"},
	{name: "South Sudan", x: 1951, y: 2021.5, size: "small"},
	{name: "Senegal", x: 1702, y: 1979, size: "small"},
	{name: "Cabo Verde", x: 1651, y: 1979, size: "small"},
	{name: "Sierra Leone", x: 1718, y: 2018.5, size: "tiny"},
	{name: "El Salvador", x: 1285, y: 1985.5, size: "tiny"},
	{name: "Serbia", x: 1891, y: 1788.5, size: "tiny"},
	{name: "Suriname", x: 1465, y: 2047.5, size: "small"},
	{name: "Slovakia", x: 1882, y: 1761.5, size: "tiny"},
	{name: "Slovenia", x: 1859, y: 1778.5, size: "tiny"},
	{name: "Sweden", x: 1850, y: 1680.5, size: "small"},
	{name: "Eswatini", x: 1956, y: 2242.5, size: "tiny"},
	{name: "Syria", x: 1991, y: 1849.5, size: "small"},
	{name: "Chad", x: 1883, y: 1972.5, size: "medium"},
	{name: "Togo", x: 1790, y: 2017.5, size: "tiny"},
	{name: "Thailand", x: 2347, y: 1971.5, size: "medium"},
	{name: "Tajikistan", x: 2159, y: 1822.5, size: "small"},
	{name: "Turkmenistan", x: 2095, y: 1821.5, size: "small"},
	{name: "Timor-Leste", x: 2496, y: 2128.5, size: "tiny"},
	{name: "Tunisia", x: 1834, y: 1855.5, size: "small"},
	{name: "Turkey", x: 1966, y: 1821.5, size: "medium"},
	{name: "Taiwan", x: 2453, y: 1920.5, size: "tiny"},
	{name: "Tanzania", x: 1981, y: 2113, size: "small"},
	{name: "Uganda", x: 1966, y: 2063, size: "small"},
	{name: "Ukraine", x: 1933, y: 1755.5, size: "medium"},
	{name: "Uruguay", x: 1483, y: 2281.5, size: "small"},
	{name: "Uzbekistan", x: 2113, y: 1808.5, size: "small"},
	{name: "Venezuela", x: 1403, y: 2021.5, size: "medium"},
	{name: "Vietnam", x: 2386, y: 2000, size: "small"},
	{name: "Yemen", x: 2052, y: 1970.5, size: "small"},
	{name: "Zambia", x: 1925, y: 2166.5, size: "medium"},
	{name: "Zimbabwe", x: 1946, y: 2193.5, size: "small"},
	{name: "Somalia", x: 2045, y: 2038.5, size: "small"},
	{name: "Kosovo", x: 1892, y: 1799.5, size: "tiny"},
	{name: "South Africa", x: 1913, y: 2251.5, size: "medium"},
	{name: "New Zealand", x: 2659, y: 2352.5, size: "tiny"},
	{name: "Chile", x: 1403, y: 2299.5, size: "small"},
	{name: "Netherlands", x: 1809, y: 1739.5, size: "tiny"},
	{name: "Portugal", x: 1740, y: 1818.5, size: "tiny"},
	{name: "Russia", x: 2234, y: 1701.5, size: "large"},
	{name: "Spain", x: 1762, y: 1816.5, size: "medium"},
	{name: "France", x: 1792, y: 1773.5, size: "medium"},
	{name: "United States", x: 1243, y: 1832.5, size: "large"},
	{name: "French Guiana", x: 1482, y: 2047.5, size: "tiny"},
	{name: "Aruba", x: 1391, y: 1990.5, size: "tiny"},
	{name: "American Samoa", x: 825, y: 2162.5, size: "tiny"},
	{name: "Antigua and Barbuda", x: 1437, y: 1961.5, size: "tiny"},
	{name: "Saint Kitts and Nevis", x: 1437, y: 1961.5, size: "tiny"},
	{name: "Anguilla", x: 1437, y: 1961.5, size: "tiny"},
	{name: "Bahrain", x: 2063, y: 1902.5, size: "tinier"},
	{name: "Bermuda", x: 1435, y: 1864.5, size: "tiny"},
	{name: "Barbados", x: 1449, y: 1986.5, size: "tiny"},
	{name: "Comoros", x: 2029, y: 2143.5, size: "tiny"},
	{name: "Cyprus", x: 1961, y: 1848.5, size: "tiny"},
	{name: "Falkland Islands", x: 1495, y: 2397.5, size: "tiny"},
	{name: "Faeroe Islands", x: 1753, y: 1677.5, size: "tiny"},
	{name: "Grenada", x: 1437, y: 1992.5, size: "tiny"},
	{name: "Guam", x: 2599, y: 1983.5, size: "tiny"},
	{name: "Saint Lucia", x: 1442, y: 1981.5, size: "tiny"},
	{name: "Saint Barthélemy", x: 1442, y: 1981.5, size: "tiny"},
	{name: "Saint Lucia", x: 1449, y: 1986.5, size: "tiny"},
	{name: "Maldives", x: 2201, y: 2044.5, size: "tiny"},
	{name: "Saint Martin", x: 2201, y: 2044.5, size: "tiny"},
	{name: "Saint Vincent and the Grenadines", x: 1449, y: 1986.5, size: "tiny"},
	{name: "Marshall Islands", x: 2752, y: 2026.5, size: "tiny"},
	{name: "Malta", x: 1861, y: 1839.5, size: "tiny"},
	{name: "Northern Mariana Islands", x: 2602, y: 1972.5, size: "tiny"},
	{name: "Mauritius", x: 2105, y: 2199.5, size: "tiny"},
	{name: "New Caledonia", x: 2704, y: 2201.5, size: "tiny"},
	{name: "Nauru", x: 2731, y: 2074.5, size: "tiny"},
	{name: "Palau", x: 2545, y: 2022.5, size: "tiny"},
	{name: "Puerto Rico", x: 1413, y: 1955.5, size: "tiny"},
	{name: "French Polynesia", x: 949, y: 2183.5, size: "tiny"},
	{name: "Solomon Islands", x: 2684, y: 2120.5, size: "tiny"},
	{name: "São Tomé and Principe", x: 1819, y: 2068.5, size: "tiny"},
	{name: "Seychelles", x: 2046, y: 2131.5, size: "tiny"},
	{name: "Tonga", x: 811, y: 2206.5, size: "tiny"},
	{name: "Trinidad and Tobago", x: 1438, y: 2002.5, size: "tiny"},
	{name: "Tuvalu", x: 2797, y: 2126.5, size: "tiny"},
	{name: "British Virgin Islands", x: 1424, y: 1952.5, size: "tiny"},
	{name: "Vanuatu", x: 2721, y: 2164.5, size: "tiny"},
	{name: "Samoa", x: 814, y: 2157.5, size: "tiny"},
	{name: "Martinique", x: 1442, y: 1976.5, size: "tiny"},
	{name: "Mayotte", x: 2038, y: 2153, size: "tiny"},
	{name: "Fiji", x: 2788, y: 2175.5, size: "tiny"},
];
