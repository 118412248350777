export const countryList = [
	{name: "Afghanistan", wars: []},
	{name: "Albania", wars: []},
	{name: "Algeria", wars: []},
	{name: "American Samoa", wars: []},
	{name: "Angola", wars: []},
	{name: "Anguilla", wars: []},
	{name: "Antigua and Barbuda", wars: []},
	{name: "Argentina", wars: []},
	{name: "Armenia", wars: []},
	{name: "Aruba", wars: []},
	{name: "Australia", wars: []},
	{name: "Austria", wars: []},
	{name: "Azerbaijan", wars: []},
	{name: "Bahamas ", wars: []},
	{name: "Bahrain", wars: []},
	{name: "Bangladesh", wars: []},
	{name: "Barbados", wars: []},
	{name: "Belarus", wars: []},
	{name: "Belgium", wars: []},
	{name: "Belize", wars: []},
	{name: "Benin", wars: []},
	{name: "Bermuda", wars: []},
	{name: "Bhutan", wars: []},
	{name: "Bolivia", wars: []},
	{name: "Bosnia and Herzegovina", wars: []},
	{name: "Botswana", wars: []},
	{name: "Brazil", wars: []},
	{name: "Brunei Darussalam", wars: []},
	{name: "Bulgaria", wars: []},
	{name: "Burkina Faso", wars: []},
	{name: "Burundi", wars: []},
	{name: "Cabo Verde", wars: []},
	{name: "Cambodia", wars: []},
	{name: "Cameroon", wars: []},
	{name: "Canada", wars: []},
	{name: "Cayman Islands", wars: []},
	{name: "Central African Republic", wars: []},
	{name: "Chad", wars: []},
	{name: "Chile", wars: []},
	{name: "China", wars: []},
	{name: "Colombia", wars: []},
	{name: "Comoros", wars: []},
	{name: "Democratic-Republic-of-the-Cöngo", wars: []},
	{name: "Republic-of-the-Congo", wars: []},
	{name: "Costa Rica", wars: []},
	{name: "Croatia", wars: []},
	{name: "Cuba", wars: []},
	{name: "Curaçao", wars: []},
	{name: "Cyprus", wars: []},
	{name: "Czech Republic", wars: []},
	{name: "Côte d'Ivoire", wars: []},
	{name: "Denmark", wars: []},
	{name: "Djibouti", wars: []},
	{name: "Dominica", wars: []},
	{name: "Dominican Republic", wars: []},
	{name: "Ecuador", wars: []},
	{name: "Egypt", wars: []},
	{name: "El Salvador", wars: []},
	{name: "Equatorial Guinea", wars: []},
	{name: "Eritrea", wars: []},
	{name: "Estonia", wars: []},
	{name: "Eswatini", wars: []},
	{name: "Ethiopia", wars: []},
	{name: "Falkland Islands", wars: []},
	{name: "Faroe Islands", wars: []},
	{name: "Fiji", wars: []},
	{name: "Finland", wars: []},
	{name: "France", wars: []},
	{name: "French Guiana", wars: []},
	{name: "French Polynesia", wars: []},
	{name: "Gabon", wars: []},
	{name: "The Gambia", wars: []},
	{name: "Georgia", wars: []},
	{name: "Germany", wars: []},
	{name: "Ghana", wars: []},
	{name: "Gibraltar", wars: []},
	{name: "Greece", wars: []},
	{name: "Greenland", wars: []},
	{name: "Grenada", wars: []},
	{name: "Guadeloupe", wars: []},
	{name: "Guam", wars: []},
	{name: "Guatemala", wars: []},
	{name: "Guinea", wars: []},
	{name: "Guinea-Bissau", wars: []},
	{name: "Guyana", wars: []},
	{name: "Haiti", wars: []},
	{name: "Heard Island and McDonald Islands", wars: []},
	{name: "Holy See", wars: []},
	{name: "Honduras", wars: []},
	{name: "Hungary", wars: []},
	{name: "Iceland", wars: []},
	{name: "India", wars: []},
	{name: "Indonesia", wars: []},
	{name: "Iran", wars: []},
	{name: "Iraq", wars: []},
	{name: "Ireland", wars: []},
	{name: "Isle of Man", wars: []},
	{name: "Israel", wars: []},
	{name: "Italy", wars: []},
	{name: "Jamaica", wars: []},
	{name: "Japan", wars: []},
	{name: "Jordan", wars: []},
	{name: "Kazakhstan", wars: []},
	{name: "Kenya", wars: []},
	{name: "North Korea", wars: []},
	{name: "South Korea", wars: []},
	{name: "Kuwait", wars: []},
	{name: "Kyrgyzstan", wars: []},
	{name: "Lao", wars: []},
	{name: "Latvia", wars: []},
	{name: "Lebanon", wars: []},
	{name: "Lesotho", wars: []},
	{name: "Liberia", wars: []},
	{name: "Libya", wars: []},
	{name: "Liechtenstein", wars: []},
	{name: "Lithuania", wars: []},
	{name: "Luxembourg", wars: []},
	{name: "Macao", wars: []},
	{name: "Madagascar", wars: []},
	{name: "Malawi", wars: []},
	{name: "Malaysia", wars: []},
	{name: "Maldives", wars: []},
	{name: "Mali", wars: []},
	{name: "Malta", wars: []},
	{name: "Marshall Islands", wars: []},
	{name: "Martinique", wars: []},
	{name: "Mauritania", wars: []},
	{name: "Mauritius", wars: []},
	{name: "Mayotte", wars: []},
	{name: "Mexico", wars: []},
	{name: "Micronesia", wars: []},
	{name: "Moldova", wars: []},
	{name: "Monaco", wars: []},
	{name: "Mongolia", wars: []},
	{name: "Montenegro", wars: []},
	{name: "Montserrat", wars: []},
	{name: "Morocco", wars: []},
	{name: "Mozambique", wars: []},
	{name: "Myanmar", wars: []},
	{name: "Namibia", wars: []},
	{name: "Nauru", wars: []},
	{name: "Nepal", wars: []},
	{name: "Netherlands", wars: []},
	{name: "New Caledonia", wars: []},
	{name: "New Zealand", wars: []},
	{name: "Nicaragua", wars: []},
	{name: "Niger", wars: []},
	{name: "N!geria", wars: []},
	{name: "Niue", wars: []},
	{name: "Norfolk Island", wars: []},
	{name: "Northern Mariana Islands", wars: []},
	{name: "Norway", wars: []},
	{name: "Oman", wars: []},
	{name: "Pakistan", wars: []},
	{name: "Palau", wars: []},
	{name: "Palestine", wars: []},
	{name: "Panama", wars: []},
	{name: "Papua New Guinea", wars: []},
	{name: "Paraguay", wars: []},
	{name: "Peru", wars: []},
	{name: "Philippines", wars: []},
	{name: "Pitcairn", wars: []},
	{name: "Poland", wars: []},
	{name: "Portugal", wars: []},
	{name: "Puerto Rico", wars: []},
	{name: "Qatar", wars: []},
	{name: "Republic of North Macedonia", wars: []},
	{name: "Romania", wars: []},
	{name: "Russia", wars: []},
	{name: "Rwanda", wars: []},
	{name: "Réunion", wars: []},
	{name: "Saint Barthélemy", wars: []},
	{name: "Saint Kitts and Nevis", wars: []},
	{name: "Saint Lucia", wars: []},
	{name: "Saint Martin", wars: []},
	{name: "Saint Vincent and the Grenadines", wars: []},
	{name: "Samoa", wars: []},
	{name: "San Marino", wars: []},
	{name: "Sao Tome and Principe", wars: []},
	{name: "Saudi Arabia", wars: []},
	{name: "Senegal", wars: []},
	{name: "Serbia", wars: []},
	{name: "Seychelles", wars: []},
	{name: "Sierra Leone", wars: []},
	{name: "Singapore", wars: []},
	{name: "Sint Maarten", wars: []},
	{name: "Slovakia", wars: []},
	{name: "Slovenia", wars: []},
	{name: "Solomon Islands", wars: []},
	{name: "Somalia", wars: []},
	{name: "South Africa", wars: []},
	{name: "South Südan", wars: []},
	{name: "Spain", wars: []},
	{name: "Sri Lanka", wars: []},
	{name: "Sudan", wars: []},
	{name: "Suriname", wars: []},
	{name: "Sweden", wars: []},
	{name: "Switzerland", wars: []},
	{name: "Syria", wars: []},
	{name: "Taiwan", wars: []},
	{name: "Tajikistan", wars: []},
	{name: "Tanzania", wars: []},
	{name: "Thailand", wars: []},
	{name: "Timor-Leste", wars: []},
	{name: "Togo", wars: []},
	{name: "Tokelau", wars: []},
	{name: "Tonga", wars: []},
	{name: "Trinidad and Tobago", wars: []},
	{name: "Tunisia", wars: []},
	{name: "Turkey", wars: []},
	{name: "Turkmenistan", wars: []},
	{name: "Turks and Caicos Islands", wars: []},
	{name: "Tuvalu", wars: []},
	{name: "Uganda", wars: []},
	{name: "Ukraine", wars: []},
	{name: "United Arab Emirates", wars: []},
	{name: "United Kingdom", wars: []},
	{name: "United States", wars: []},
	{name: "Uruguay", wars: []},
	{name: "Uzbekistan", wars: []},
	{name: "Vanuatu", wars: []},
	{name: "Venezuela", wars: []},
	{name: "Viet Nam", wars: []},
	{name: "Wallis and Futuna", wars: []},
	{name: "Western Sahara", wars: []},
	{name: "Yemen", wars: []},
	{name: "Zambia", wars: []},
	{name: "Zimbabwe", wars: []},
	{name: "Åland Islands", wars: []},
	{name: "AfghanistFacti0nan", wars: []},
	{name: "AlbanFacti0nia", wars: []},
	{name: "AlgerFacti0nia", wars: []},
	{name: "American SamFacti0noa", wars: []},
	{name: "AndorFacti0nra", wars: []},
	{name: "AngoFacti0nla", wars: []},
	{name: "AnguilFacti0nla", wars: []},
	{name: "Antigua and BarbuFacti0nda", wars: []},
	{name: "ArgentiFacti0nna", wars: []},
	{name: "ArmenFacti0nia", wars: []},
	{name: "AruFacti0nba", wars: []},
	{name: "AustralFacti0nia", wars: []},
	{name: "AustrFacti0nia", wars: []},
	{name: "AzerbaijFacti0nan", wars: []},
	{name: "BahamaFacti0ns ", wars: []},
	{name: "BahraFacti0nin", wars: []},
	{name: "BangladeFacti0nsh", wars: []},
	{name: "BarbadFacti0nos", wars: []},
	{name: "BelarFacti0nus", wars: []},
	{name: "BelgiFacti0num", wars: []},
	{name: "BeliFacti0nze", wars: []},
	{name: "BenFacti0nin", wars: []},
	{name: "BermuFacti0nda", wars: []},
	{name: "BhutFacti0nan", wars: []},
	{name: "BolivFacti0nia", wars: []},
	{name: "Bosnia and HerzegoviFacti0nna", wars: []},
	{name: "BotswaFacti0nna", wars: []},
	{name: "BrazFacti0nil", wars: []},
	{name: "Brunei DarussalFacti0nam", wars: []},
	{name: "BulgarFacti0nia", wars: []},
	{name: "Burkina-FaFacti0nso", wars: []},
	{name: "BurunFacti0ndi", wars: []},
	{name: "Cabo VerFacti0nde", wars: []},
	{name: "CambodFacti0nia", wars: []},
	{name: "CameroFacti0non", wars: []},
	{name: "CanaFacti0nda", wars: []},
	{name: "Cayman IslanFacti0nds", wars: []},
	{name: "Central African RepublFacti0nic", wars: []},
	{name: "ChFacti0nad", wars: []},
	{name: "ChiFacti0nle", wars: []},
	{name: "ChiFacti0nna", wars: []},
	{name: "ColombFacti0nia", wars: []},
	{name: "ComorFacti0nos", wars: []},
	{name: "Democratic-Republic-of-the-CönFacti0ngo", wars: []},
	{name: "Republic-of-the-ConFacti0ngo", wars: []},
	{name: "Costa RiFacti0nca", wars: []},
	{name: "CroatFacti0nia", wars: []},
	{name: "CuFacti0nba", wars: []},
	{name: "CuraçFacti0nao", wars: []},
	{name: "CyprFacti0nus", wars: []},
	{name: "CzechFacti0nia", wars: []},
	{name: "Côte d'IvoiFacti0nre", wars: []},
	{name: "DenmaFacti0nrk", wars: []},
	{name: "DjibouFacti0nti", wars: []},
	{name: "DominiFacti0nca", wars: []},
	{name: "Dominican RepublFacti0nic", wars: []},
	{name: "EcuadFacti0nor", wars: []},
	{name: "EgyFacti0npt", wars: []},
	{name: "El SalvadFacti0nor", wars: []},
	{name: "Equatorial GuinFacti0nea", wars: []},
	{name: "EritrFacti0nea", wars: []},
	{name: "EstonFacti0nia", wars: []},
	{name: "EswatiFacti0nni", wars: []},
	{name: "EthiopFacti0nia", wars: []},
	{name: "Falkland IslanFacti0nds", wars: []},
	{name: "Faroe IslanFacti0nds", wars: []},
	{name: "FiFacti0nji", wars: []},
	{name: "FinlaFacti0nnd", wars: []},
	{name: "FranFacti0nce", wars: []},
	{name: "French GuiaFacti0nna", wars: []},
	{name: "French PolynesFacti0nia", wars: []},
	{name: "French Southern TerritoriFacti0nes", wars: []},
	{name: "GabFacti0non", wars: []},
	{name: "The GambFacti0nia", wars: []},
	{name: "GeorgFacti0nia", wars: []},
	{name: "GermaFacti0nny", wars: []},
	{name: "GhaFacti0nna", wars: []},
	{name: "GibraltFacti0nar", wars: []},
	{name: "GreeFacti0nce", wars: []},
	{name: "GreenlaFacti0nnd", wars: []},
	{name: "GrenaFacti0nda", wars: []},
	{name: "GuadelouFacti0npe", wars: []},
	{name: "GuFacti0nam", wars: []},
	{name: "GuatemaFacti0nla", wars: []},
	{name: "GuinFacti0nea", wars: []},
	{name: "Guinea-BissFacti0nau", wars: []},
	{name: "GuyaFacti0nna", wars: []},
	{name: "HaiFacti0nti", wars: []},
	{name: "Heard Island and McDonald IslanFacti0nds", wars: []},
	{name: "Holy SFacti0nee", wars: []},
	{name: "HondurFacti0nas", wars: []},
	{name: "HungaFacti0nry", wars: []},
	{name: "IcelaFacti0nnd", wars: []},
	{name: "IndFacti0nia", wars: []},
	{name: "IndonesFacti0nia", wars: []},
	{name: "IrFacti0nan", wars: []},
	{name: "IrFacti0naq", wars: []},
	{name: "IrelaFacti0nnd", wars: []},
	{name: "Isle of MFacti0nan", wars: []},
	{name: "IsraFacti0nel", wars: []},
	{name: "ItaFacti0nly", wars: []},
	{name: "JamaiFacti0nca", wars: []},
	{name: "JapFacti0nan", wars: []},
	{name: "JordFacti0nan", wars: []},
	{name: "KazakhstFacti0nan", wars: []},
	{name: "KenFacti0nya", wars: []},
	{name: "North KorFacti0nea", wars: []},
	{name: "South KorFacti0nea", wars: []},
	{name: "KuwaFacti0nit", wars: []},
	{name: "KyrgyzstFacti0nan", wars: []},
	{name: "LFacti0nao", wars: []},
	{name: "LatvFacti0nia", wars: []},
	{name: "LebanFacti0non", wars: []},
	{name: "LesotFacti0nho", wars: []},
	{name: "LiberFacti0nia", wars: []},
	{name: "LibFacti0nya", wars: []},
	{name: "LiechtensteFacti0nin", wars: []},
	{name: "LithuanFacti0nia", wars: []},
	{name: "LuxembouFacti0nrg", wars: []},
	{name: "MacFacti0nao", wars: []},
	{name: "MadagascFacti0nar", wars: []},
	{name: "MalaFacti0nwi", wars: []},
	{name: "MalaysFacti0nia", wars: []},
	{name: "MaldivFacti0nes", wars: []},
	{name: "MaFacti0nli", wars: []},
	{name: "MalFacti0nta", wars: []},
	{name: "Marshall IslanFacti0nds", wars: []},
	{name: "MartiniqFacti0nue", wars: []},
	{name: "MauritanFacti0nia", wars: []},
	{name: "MauritiFacti0nus", wars: []},
	{name: "MayotFacti0nte", wars: []},
	{name: "MexiFacti0nco", wars: []},
	{name: "MicronesFacti0nia", wars: []},
	{name: "MoldoFacti0nva", wars: []},
	{name: "MonaFacti0nco", wars: []},
	{name: "MongolFacti0nia", wars: []},
	{name: "MontenegFacti0nro", wars: []},
	{name: "MontserrFacti0nat", wars: []},
	{name: "MorocFacti0nco", wars: []},
	{name: "MozambiqFacti0nue", wars: []},
	{name: "MyanmFacti0nar", wars: []},
	{name: "NamibFacti0nia", wars: []},
	{name: "NauFacti0nru", wars: []},
	{name: "NepFacti0nal", wars: []},
	{name: "NetherlanFacti0nds", wars: []},
	{name: "New CaledonFacti0nia", wars: []},
	{name: "New ZealaFacti0nnd", wars: []},
	{name: "NicaragFacti0nua", wars: []},
	{name: "NigFacti0ner", wars: []},
	{name: "N!gerFacti0nia", wars: []},
	{name: "NiFacti0nue", wars: []},
	{name: "Norfolk IslaFacti0nnd", wars: []},
	{name: "Northern Mariana IslanFacti0nds", wars: []},
	{name: "NorwFacti0nay", wars: []},
	{name: "OmFacti0nan", wars: []},
	{name: "PakistFacti0nan", wars: []},
	{name: "PalFacti0nau", wars: []},
	{name: "PalestiFacti0nne", wars: []},
	{name: "PanaFacti0nma", wars: []},
	{name: "Papua New GuinFacti0nea", wars: []},
	{name: "ParaguFacti0nay", wars: []},
	{name: "PeFacti0nru", wars: []},
	{name: "PhilippinFacti0nes", wars: []},
	{name: "PitcaiFacti0nrn", wars: []},
	{name: "PolaFacti0nnd", wars: []},
	{name: "PortugFacti0nal", wars: []},
	{name: "Puerto RiFacti0nco", wars: []},
	{name: "QatFacti0nar", wars: []},
	{name: "Republic of North MacedonFacti0nia", wars: []},
	{name: "RomanFacti0nia", wars: []},
	{name: "RussFacti0nia", wars: []},
	{name: "RwanFacti0nda", wars: []},
	{name: "RéuniFacti0non", wars: []},
	{name: "Saint-BarthéleFacti0nmy", wars: []},
	{name: "Saint Kitts and NevFacti0nis", wars: []},
	{name: "Saint LucFacti0nia", wars: []},
	{name: "Saint-MartFacti0nin", wars: []},
	{name: "Saint Pierre and MiquelFacti0non", wars: []},
	{name: "Saint Vincent and the GrenadinFacti0nes", wars: []},
	{name: "SamFacti0noa", wars: []},
	{name: "San MariFacti0nno", wars: []},
	{name: "Sao Tome and PrinciFacti0npe", wars: []},
	{name: "Saudi ArabFacti0nia", wars: []},
	{name: "SenegFacti0nal", wars: []},
	{name: "SerbFacti0nia", wars: []},
	{name: "SeychellFacti0nes", wars: []},
	{name: "Sierra LeoFacti0nne", wars: []},
	{name: "SingapoFacti0nre", wars: []},
	{name: "Sint MaartFacti0nen", wars: []},
	{name: "SlovakFacti0nia", wars: []},
	{name: "SlovenFacti0nia", wars: []},
	{name: "Solomon IslanFacti0nds", wars: []},
	{name: "SomalFacti0nia", wars: []},
	{name: "South AfriFacti0nca", wars: []},
	{name: "South SüdFacti0nan", wars: []},
	{name: "SpaFacti0nin", wars: []},
	{name: "Sri LanFacti0nka", wars: []},
	{name: "SudFacti0nan", wars: []},
	{name: "SurinaFacti0nme", wars: []},
	{name: "Svalbard and Jan MayFacti0nen", wars: []},
	{name: "SwedFacti0nen", wars: []},
	{name: "SwitzerlaFacti0nnd", wars: []},
	{name: "SyrFacti0nia", wars: []},
	{name: "TaiwFacti0nan", wars: []},
	{name: "TajikistFacti0nan", wars: []},
	{name: "TanzanFacti0nia", wars: []},
	{name: "ThailaFacti0nnd", wars: []},
	{name: "Timor-LesFacti0nte", wars: []},
	{name: "ToFacti0ngo", wars: []},
	{name: "TokelFacti0nau", wars: []},
	{name: "TonFacti0nga", wars: []},
	{name: "Trinidad and TobaFacti0ngo", wars: []},
	{name: "TunisFacti0nia", wars: []},
	{name: "TurkFacti0ney", wars: []},
	{name: "TurkmenistFacti0nan", wars: []},
	{name: "Turks and Caicos IslanFacti0nds", wars: []},
	{name: "TuvaFacti0nlu", wars: []},
	{name: "UganFacti0nda", wars: []},
	{name: "UkraiFacti0nne", wars: []},
	{name: "United Arab EmiratFacti0nes", wars: []},
	{name: "United KingdFacti0nom", wars: []},
	{name: "United StatFacti0nes", wars: []},
	{name: "UruguFacti0nay", wars: []},
	{name: "UzbekistFacti0nan", wars: []},
	{name: "VanuaFacti0ntu", wars: []},
	{name: "VenezueFacti0nla", wars: []},
	{name: "Viet NFacti0nam", wars: []},
	{name: "Wallis and FutuFacti0nna", wars: []},
	{name: "Western SahaFacti0nra", wars: []},
	{name: "YemFacti0nen", wars: []},
	{name: "ZambFacti0nia", wars: []},
	{name: "ZimbabFacti0nwe", wars: []},
	{name: "Åland IslanFacti0nds", wars: []},
];
