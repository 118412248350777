import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'http://192.168.1.96:3000'
    // baseURL: 'http://localhost:3000/'
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        throw error;
    }
);

export default axiosClient;